<template>
  <main id="app">
    <NavbarComponent message="IT Expert." @open-modal-contact="openModalContact" :menus="menus" />
    <section >
      <router-view  @open-modal-contact="openModalContact"></router-view>
    </section>
    <FooterComponent />
    <WhatsAppButton phoneNumber="+573186195270" company-name="Edwin Ariza" company-logo="https://edwinariza.com/static/favicon.png" text="Hey Edwin, Could you please help me with ..." />
    <ModalContactComponent  :visible="isModalContact" @close="closeModalContact"/>
  </main>
</template>

<script>
import NavbarComponent from "../../edwinariza.ui/components/NavBar/NavBarComponent.vue";
import WhatsAppButton from "@/../../jumichica.vue.widget.whatsapp/src/components/Widget.vue";
import FooterComponent from "../../edwinariza.ui/components/Footer/FooterComponent.vue";
import ModalContactComponent from "@/components/Modals/ModalContactComponent.vue";
export default {
  name: 'App',
  data(){
    return {
      isModalContact:false,
      loading:true,
      menus: [
        { id:1, name: { ES: 'Servicios', EN:'Services' }, url:'', submenus: [
            {id: 1, name: { ES:'Coordinación de proyecto', EN:'Project Management' }, url: '/project_manager'},
            {id: 2, name: { ES: 'Formulación de Proyecto', EN:'Project Formulation'}, url: '/formulator'},
            {id: 3, name: { ES: 'Proyecto de Investigación', EN:'Research Project'}, url: '/researcher'}
        ]},
        { id:2, name: { ES:'Herramientas', EN:'Toolkit' }, url:'', submenus: [
            {id: 1, name: { ES: 'Timebox', EN:'Timebox' }, url: '/toolkit/timebox'}
        ]},
        { id: 3, name: { ES: 'Blog', EN: 'Blog' }, url: '/blog' }
      ]
    }
  },
  components: {
    ModalContactComponent,
    FooterComponent,
    WhatsAppButton,
    NavbarComponent
  },
  methods:{
    openModalContact(){
      this.isModalContact=true;
    },
    closeModalContact(){
      this.isModalContact=false;
    }
  }
}
</script>
<style>
  html{
    font-size: 16px;
    font-family: "Quicksand", sans-serif;
  }
  #app {
    font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    background:#fefefe;
  }
  *{
    font-family:"Quicksand", sans-serif;
  }
</style>
